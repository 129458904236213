import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from "@types";

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <div style={{color: fill}}>words.</div>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  font-family: ${p => p.theme.fonts.serif};
  font-weight: bold;
  font-size: 24px;
`;
